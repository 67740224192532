
import { defineComponent } from 'vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import { mdiChevronUp } from '@mdi/js';
import EntryIndex from '../../../components/Frontend/EntryIndex.vue';

export default defineComponent({
  meta: {
    de: {
      title: 'Environmental Social Governance | Styria Media Group',
      keywords: ['enviromental social governance', 'ethik', 'styria ethics', 'joanneum', 'styria care', 'palliative care'],
      description: 'Qualifizierte Information und öffentliche Meinungsbildung in Themen und Fragen der Ethik liegen der Styria Media Group besonders am Herzen.',
      image: require('@/assets/img/og/ueber-uns.png'),
    },
    en: {
      title: 'Environmental Social Governance | Styria Media Group',
      keywords: ['csr', 'coporate social responsibility', 'ethik', 'styria ethics', 'joanneum', 'styria care', 'palliative care'],
      description: 'Qualified information and public opinion-forming on topics and questions of ethics are particularly important to the Styria Media Group.',
      image: require('@/assets/img/og/ueber-uns.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    Footer,
    EntryIndex,
  },
  setup() {
    return {
      mdiChevronUp,
    };
  },
});
